$(document).ready(function () {

  $("#searchForm").on("submit", function (e) {
    e.preventDefault();

    var element = document.createElement('a');
    element.setAttribute('href', "http://" + $('#query').val().replace("http://", "").replace("https://", ""));

    fqdn = element.hostname.toLowerCase();

    if (fqdn.indexOf(".") != -1) {
      console.log("Checking certificate on:" + fqdn);
      document.location = "/" + fqdn;

      return;
    }
  });

  // $( "#query" ).keyup(() => {
  //   if($( "#query" ).val().length < 3) {
  //     return;
  //   }

  //   if ($('#autocomplete [value="' + $( "#query" ).val() + '"]').length) {
  //     document.location = $('#autocomplete [value="' + $( "#query" ).val() + '"]').data('value')
  //   }

  //   $.getJSON("/api/bydomain?q=" + $( "#query" ).val().toLowerCase(), function (data) {
  //     $("#autocomplete").empty();
  //     $.each(data, function (key, val) {
  //       $("#autocomplete").append($("<option>").attr('value', key).attr('data-value', val));
  //     });
  //     $( "#query" ).focus();
  //   });
  // });
});

// get alternative sites based on this fqdn
function suggestions(fqdn) {
  // if (!fqdn) {
  //   return
  // }
  // var url = "/api/list/" + fqdn;

  // $.getJSON(url, function (data) {
  //   if (!Array.isArray(data) || !data.length) {
  //     return
  //   }

  //   $('#alternatives').show()
  //   $.each(data, function (key, val) {
  //     $('#suggestions').append(`<li><a href="${val.Link}" title="${val.CommonName}"> <strong>${val.CommonName}</strong> issued by ${val.Issuer} and expiry date ${val.Expires}</a></li>`)
  //   });
  // });
}