$(document).ready(function () {

  $('#downloadCertificate').on("click", function(){
    var element = document.createElement('a');
    element.setAttribute('href', 'data:application/pkix-cert;charset=utf-8,' + encodeURIComponent($('#pemCertificate').text()));
    element.setAttribute('download', $('#downloadCertificate').data('filename').replace(/\s/g, ""));

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  });

});